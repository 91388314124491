import type { ActorRefFrom } from "xstate";
import { assign, createMachine, spawn } from "xstate";
import { eventBus } from "~/state/events";
import type { Notification } from "./toast-root";
import { actorSystem } from "~/state";
import { useActorFromSystem } from "~/state/system";
import { useCallback, useEffect } from "react";

export const createToasterMachine = () => {
  /** @xstate-layout N4IgpgJg5mDOIC5QBcD2BDWywCcB06AxsgJYBuYAxAHIDyAKgJIBiAmgNoAMAuoqAA6pYJUqgB2fEAA9EARgDMAVjwAOWSsUBOFZsWcAbIvm6ANCACeiefIAseAOyb9nXfZs3Fs-boC+Ps2iY2PhEpBSUACKMAMoAsjHRXLxIIILCohIpMgiy9rJ4ijYATEW2xUW5hSpmlggAtPryeNb6uSpFNrkd8ip+-iBiqBBwkoFYuJJpIiTiktl1NtUWiHWyeqoVGt6aRUadfgEY4yHE5GCTQtOzWYicNXLufT5AA */
  return createMachine(
    {
      id: "toaster",
      initial: "active",
      predictableActionArguments: true,
      tsTypes: {} as import("./toast.machine.typegen").Typegen0,
      schema: {
        context: {} as ToasterMachineContext,
        events: {} as ToasterMachineEvent | ToasterGlobalEvent,
      },
      invoke: {
        id: "event-bus",
        src: () => eventBus.events$,
      },
      context: {
        toasts: [],
      },
      states: {
        active: {
          on: {
            NOTIFY: {
              actions: ["createToast"],
            },
            "TOAST.NOTIFY": {
              actions: ["createToast"],
            },
          },
        },
      },
    },
    {
      actions: {
        createToast: assign((ctx, event) => {
          return {
            toasts: [...ctx.toasts, spawn(createToastMachine(event.notif))],
          };
        }),
      },
    },
  );
};

export type ToasterActorRef = ActorRefFrom<ReturnType<typeof createToasterMachine>>;

const createToastMachine = (notif: Notification) => {
  return createMachine({
    id: "toast",
    initial: "active",
    schema: {
      context: {} as Notification,
    },
    context: notif,
    states: {
      active: {
        on: {
          DISMISS: { target: "inactive" },
        },
      },
      inactive: { type: "final" },
    },
  });
};

interface ToasterMachineContext {
  toasts: ToastActorRef[];
}

export type ToastActorRef = ActorRefFrom<ReturnType<typeof createToastMachine>>;

type ToasterMachineEvent = {
  type: "NOTIFY";
  notif: Notification;
};

export type ToasterGlobalEvent = {
  type: "TOAST.NOTIFY";
  notif: Notification;
};

const createToaster = () => {
  let ref = actorSystem.get<ToastActorRef>(`toasts`);
  if (!ref) {
    const machine = createToasterMachine();
    ref = actorSystem.registerMachine(machine, `toasts`);
  }
  return ref;
};

export const useToaster = () => {
  const [state, ref] = useActorFromSystem<ToasterActorRef>(`toasts`);
  useEffect(() => {
    if (ref) return;
    createToaster();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const notify = useCallback(
    (notif: Notification) => {
      ref?.send({ type: "NOTIFY", notif });
    },
    [ref],
  );

  if (!ref || !state)
    return {
      isReady: false,
    } as const;
  return { notify, toasts: state.context.toasts };
};
